export default class modal {
  constructor() {
    //Youtube動画モーダル
    $('.js-modal-video').modaal({
      type: 'video'
    });
    //工場案内ページモーダル
    $('.factory-gallery').modaal({
      type: 'image'
    });
  }
}
