// import SmoothScroll from "smooth-scroll";

export default class Common {
  constructor() {
    // let scroll = new SmoothScroll('a[href*="#"]',{
    //   header: '[data-scroll-header]',
    //   speed: 1,
    // });
    // SmoothScroll();

    let close = false;
    let st = 0;
    $('#hamburger').on('click', function() {
      if (close !== true) {
        // スクロールを禁止する
        st = $(window).scrollTop();
        $('body').addClass('js-fixed').css({'top': -st});
        close = true;
        // gnavを表示
        $('#gnav').addClass('is-show');
        // ハンバーガーボタンを閉じるボタンにする
        $('#hamburger').addClass('is-active');

        $('.submenu-link').on('click', function() {
          close = false;
          $('body').removeClass('js-fixed');
          // gnav非表示
          $('#gnav').removeClass('is-show');
          // ハンバーガーボタンを通常に戻す
          $('#hamburger').removeClass('is-active');
        });
      }
      else {
        // gnav非表示
        $('#gnav').removeClass('is-show');
        close = false;
        // ハンバーガーボタンを通常に戻す
        $('#hamburger').removeClass('is-active');
        // // スクロールを許可する
        $('body').removeClass('js-fixed').css({'top': 0});
        window.scrollTo(0, st);
      }
    });

    $('.submenu-btn').children('.gnav-link').on('click', (function(e){
      var winW = window.innerWidth;
      var spW = 1024;
      // SPサイズのときは、サブメニューを表示
      if (winW <= spW ) {
        $(this).siblings('.submenu').stop().slideToggle();
        $(this).toggleClass('is-show');
      }
    }));

    //リサイズの処理
    $(window).resize(function() {
      if ($(window).width() > 1024) {
        $(".submenu").css({'display': ''},{'height': 'auto'});
        if ($('#gnav').hasClass('is-show')) {
          $('#gnav').removeClass('is-show');
          $('#hamburger').removeClass('is-active');
          $('.submenu-btn').children('.gnav-link').removeClass('is-show');
          close = false;
          // スクロールを許可する
          $('body').removeClass('js-fixed').css({'top': 0});
          window.scrollTo(0, st);
        }
      }
    });

    $(function () {
      var headH = $("header").outerHeight();
      var urlHash = location.hash; //URLのハッシュタグを取得
      if (urlHash) { //ハッシュタグが有る場合
          $("body,html").scrollTop(0);
          setTimeout(function () { //無くてもいいが有ると動作が安定する
              var target = $(urlHash);
              var position = target.offset().top - headH;
              $("body,html").stop().animate({
                  scrollTop: position
              }, 1);
          }, 10);
          return false;
      }
    });
  }
}

