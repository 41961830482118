import Swiper from 'swiper/dist/js/swiper.min.js';
import Modaal from 'Modaal';
import SmoothScroll from 'smooth-scroll';
import Accordion from "./class/Accordion";
import Modal from "./class/modal";
import Common from "./class/common";

new Common();
new Accordion();
new Modal();

if ($('main').hasClass('p-index') === true) {

  $(function(){
    $.ajax({
      url: 'https://nisshodenki.microcms.io/api/v1/information',
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-MICROCMS-API-KEY': 'f6ecbc6a2f124c33be76009d3239d11486cf'
      },
    })
    .done(function(data) {

      var lines = '';
      // 各値の取得
      $.each(data.contents, function(index, value) {
        var date = value.date.slice(0,10).replace(/-/g, '.');
        var title = value.title;
        var link = value.link;
        var tag = value.tag.map(item => item.tagname);
        var tagname = [];
        for (var i =0; i<tag.length; i++){
          tagname.push('<span class="info-tag">' + tag[i] + '</span>');
        }

        if (link === undefined) {
          // リンクが未設定の場合、下記DOMを生成
          lines += '<li class="swiper-slide">\
            <div class="info-inner">\
            <span class="info-time">'+ date +'</span>\
            '+ tagname.join('') +'\
            <p class="info-post__ttl">'+ title +'</p>\
            </div></li>';
          } else {
          // リンクが設定されている場合、下記DOMを生成
          lines += '<li class="swiper-slide">\
            <div class="info-inner">\
            <span class="info-time">'+ date +'</span>\
            '+ tagname.join('') +'\
            <a class="info-post__ttl link" href="'+ link +'" target="_blank">'+ title +'</a>\
            </div></li>';
        }

      })
      $('#information').html(lines);
      setinformationSwiper();
    })
    .fail(function() {
      console.log('data');
    });

  });

  let mvSwiper;
  let informationSwiper;
  let factorySwiper;

  $(window).on('load', function(){
      setMvSwiper();
      setfactorySwiper();
  });

  function setMvSwiper() {
    mvSwiper = new Swiper('.mvSwiper', {
      // オプション
      speed: 2000,
      slidesPerView: 'auto',
      loop: true,
      loopAdditionalSlides: 0,
      preventInteractionOnTransition: false,
      preventLinks: false,
      autoplay: {
        disableOnInteraction: false,
      },
      effect: "fade",
      pagination: {
        el: '.mv-pagination',
        // clickable: true,
        type: 'bullets'
      },
      paginationClickable: false,
      navigation: {
        nextEl: '.next',
        prevEl: '.prev',
      },
    });
  }

  function setinformationSwiper() {
    informationSwiper = new Swiper('.informationSwiper', {
      // オプション
      direction: 'vertical',
      init: true,
      mousewheel: {
        forceToAxis: false,
        invert: false
      },
      keyboard: true,
      navigation: {
        prevEl: '.prev',
        nextEl: '.next'
      },
    });
  }


  function setfactorySwiper() {
    factorySwiper = new Swiper('.factorySwiper', {
      // オプション
      loop: true,
      slidesPerView: 6,
      speed: 10000,
      SimulateTouch: false,
      allowTouchMove: false,
      preventLinks: false,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      breakpoints:{
        // 横幅が320px以下なら
        768:{
            slidesPerView: 'auto',
        },
      }
    });
  }

  //背景画像モノクロ ⇄ カラー
  $(function(){
    $(".bg-change-btn").hover(
     function () {
     $(this).parents("section").addClass("is-active");
     },
     function () {
     $(".bg-color").removeClass("is-active");
     }
    );
  });
}
