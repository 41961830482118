export default class Accordion {
  constructor() {
    const $accordion = $('.js-accordion');

    $accordion.each(function() {
      const $accordionBtn  = $('.js-accordion-Btn', this);
      const $accordionCont  = $('.js-accordion-Cont', this);

      $accordionBtn.on('click', function() {
          $(this).toggleClass('is-active');
          $accordionCont.slideToggle(300);
      });
    });
  }
}
